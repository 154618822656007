import React from "react";
import BurnAndAddress from "BurnAndAddress";
function Home() {
  return (
    <>
      <BurnAndAddress />
    </>
  );
}

export default Home;
