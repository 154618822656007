import { Grid, Typography, Box, Button} from '@mui/material';
import { BurnedNftCard } from 'components/Cards/BurnedNftCard';
import { NftCard } from 'components/Cards/NftCard';
import { useContractStore } from 'contexts/ContractStoreProvider';
import React from 'react';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import './components/Cards/style.css';
import SwapCardsBurn from './components/SectionStory/SwapCardsBurn';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

function BurnAndAddress() {
  const { connected } = useWallet();
  const { allNfts, burnedNfts, isLoading } = useContractStore();
  /* const isLoading = false;
  const allNfts: any[] = [
    {
      mint: 'test1',
      src: 'https://via.placeholder.com/150',
      name: 'My Cool NFT',
    },
  ];
  const burnedNfts: any[] = [
    {
      mint: 'test2',
      src: 'https://via.placeholder.com/150',
      name: 'My Cool and burned NFT',
    },
  ]; */
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!allNfts || !burnedNfts || isLoading) {
    return (
      <>
        <Header />
        <Box
          display='flex'
          alignItems='center'
          flexDirection='column'
          paddingLeft='20px'
          paddingRight='20px'
        >
          <h1 className="title title-loading">Searching for NFTs</h1>
        </Box>
        <Footer/>
      </>
    );
  }
  if (!connected) {
    return (
      <>
        <Header />
        <Box
          display='flex'
          alignItems='center'
          flexDirection='column'
          paddingLeft='20px'
          paddingRight='20px'
        >
          <h2 className="subtitle">
            THE NEW EXPLORERS CLUB
          </h2>
          <h1 className="title title-welcome">
            Welcome to The New Explorers Club.
          </h1>
          <div className="cta-connect">
            <WalletMultiButton />
          </div>
          <div className="tickets-wrap">
            <div className="tickets">
              <div className="ticket">
                <div className="ticket-number">1</div>
                <div className="ticket-badge">Sep 21 - 25, 2022</div>
                <div className="ticket-icon">
                  <img src="/Burn_icon.svg" />
                </div>
                <div className="ticket-title">
                  Burn your Genesis Card
                </div>
                <div className="ticket-content">
                  Burning 1 Genesis Citizen Card gives you a WL spot for 1 New Explorer's Club NFT.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket-number">2</div>
                <div className="ticket-icon">
                  <img src="/WL_icon.svg" />
                </div>
                <div className="ticket-title">
                  You are on the whitelist
                </div>
                <div className="ticket-content">
                  Once your Genesis Citizen card is burned you’ll get 1 whitelist spot for each card you’ve burned.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket-number">3</div>
                <div className="ticket-badge">Sep 26, 2022</div>
                <div className="ticket-icon">
                  <img src="/ME_icon.svg" />
                </div>
                <div className="ticket-title">
                  Mint your New Explorer’s Club NFT on Magic Eden
                </div>
                <div className="ticket-content">
                  1 whitelist spot gives you 1 New Explorers Club mint on MagicEden on the day of minting.
                </div>
              </div>
            </div>
          </div>
        </Box>
        <Footer/>
      </>
    );

  }
  if (allNfts.length == 0 && burnedNfts.length == 0) {
    return (
      <>
        <Header />
        <Box
          display='flex'
          alignItems='center'
          flexDirection='column'
          paddingLeft='20px'
          paddingRight='20px'
        >
          <h2 className="subtitle">
            THE NEW EXPLORERS CLUB
          </h2>
          <h1 className="title">
            You have no Genesis Citizen Cards in your wallet.
          </h1>
          <a className="cta" href="https://magiceden.io/marketplace/portals_citizen_cards">
            Buy on Magic Eden
          </a>
        </Box>
        <Footer/>
      </>
    );
  }
  return (
    (allNfts.length || burnedNfts.length) && (
      <>
        <Header />
        <Box
          display='flex'
          alignItems='center'
          flexDirection='column'
          paddingLeft='20px'
          paddingRight='20px'
        >
          <h2 className="subtitle">
            THE NEW EXPLORERS CLUB
          </h2>
          <h1 className="title">
            Burn your Genesis Citizen Card to get your WL spot.
          </h1>
          <div className="paragraph">
            You are now whitelisted for <div><span>{burnedNfts.length}</span></div> The New Explorers Club NFTs.
          </div>
          <div className="nfts">
            {
              burnedNfts.map((nft) => {
                return (
                  <NftCard name={nft.name} image={nft.src} mint={nft.mint} burned={true} />
                );
              })
            }
            {
              allNfts.map((nft) => {
                return (
                  <NftCard name={nft.name} image={nft.src} mint={nft.mint} burned={false} />
                );
              })
            }
          </div>
        </Box>
        <Footer/>
      </>
    )
  );
}
export default BurnAndAddress;
