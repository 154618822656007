import React from 'react';
import { Container, Row, Col, List } from 'bootstrap-4-react';
import classNames from 'classnames';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

const Header = () => {
  return (
    <header className="header">
      <div className="header-bar">
        BURN WINDOW IS CLOSED
      </div>
      <div className="header-container">
        <div className="header-center">
          <img
            src='/NEc_logo.svg'
            alt="NEc Logo"
          />
        </div>
        <div className="header-right">
          <a href="https://twitter.com/TheNewExpClub" target="_blank" rel="noreferrer">
            <img className="icon-twitter" src="/Twitter_icon.svg" />
          </a>
          <a href="https://discord.gg/9uMBaCPW3f" target="_blank" rel="noreferrer">
            <img className="icon-discord" src="/Discord_icon.png" />
          </a>
          <WalletMultiButton />
        </div>
      </div>
    </header>
  );
}

export default Header;
