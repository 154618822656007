import { CardActionArea,Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { SubmitButtonType1 } from "components/Form/inputs";
import { useContractStore } from "contexts/ContractStoreProvider";
import { Formik } from "formik";
import * as React from "react";
import './style.css'; 
export const NftCard = ({ name, image, mint, burned }) => {
  const { onClickHandleBurn, getAllNfts } = useContractStore();

  return (
    <div className="nft">
      {
        (burned) &&
        <div className="nft-check">
          <img src="/checkmark_icon.svg" alt="check" />
        </div>
      }
      <a href={`https://solscan.io/token/${mint}`} className={`nft-image${(burned) ? ' burned' : ''}`}>
        <img src={image} alt="ntf" />
      </a>
      <a href={`https://solscan.io/token/${mint}`} className={`nft-name${(burned) ? ' burned' : ''}`}>
        {name}
      </a>
      {
        (burned) &&
        <div className="nft-cta burned">
          Burned
        </div>
      }
      {
        (!burned) &&
        <Formik
          initialValues={{}}
          onSubmit={async (values, { setSubmitting }) => {
            await onClickHandleBurn(mint);
            await getAllNfts();
            setSubmitting(false);
          }}
        >
           {({ handleSubmit }) => (
            <form>
              <button type="submit" className="nft-cta">
                Burn card
              </button>
            </form>
          )}
        </Formik>
      }
    </div>
  );

  return (
    <Card className="mintCard">
      <CardActionArea>
        <CardMedia component="img" image={image} alt="nft" />
        <CardContent className="cardContent">
          <Typography className="mintAddyHeader" gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Typography  className="mintAddy"  >
          <a href={`https://solscan.io/token/${mint}`} className="ggs">View Mint Address </a>
          </Typography>
          <Formik
            initialValues={{}}
            onSubmit={async (values, { setSubmitting }) => {
              await onClickHandleBurn(mint);
              await getAllNfts();
              setSubmitting(false);
            }}
          >
             {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Button type="submit" className="mintBtn"  variant="contained">
            Burn
           </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
