import React from 'react';
import IconPortals from '../../icons/IconPortals';
import { Container, Row, Col, List, BA } from 'bootstrap-4-react';
import classNames from 'classnames';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-center mobile">
        <img
          src="/Footer image.svg"
          alt="footer new explorers club whitelist" 
        />
      </div>
      <div className="footer-left">
        <IconPortals className="footer-left-icon" /> <div className="footer-left-copy">&copy; 2022 Portals Labs, Inc.</div>
      </div>
      <div className="footer-center desktop">
        <img
          src="/Footer image.svg"
          alt="footer new explorers club whitelist" 
        />
      </div>
      <div className="footer-right">
      </div>
      
    
    </footer>
  );
}

export default Footer;
