export type Burn = {
  "version": "0.1.0",
  "name": "burn",
  "instructions": [
    {
      "name": "initConfig",
      "accounts": [
        {
          "name": "burnConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "verifiedCreator",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "deleteConfig",
      "accounts": [
        {
          "name": "burnConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "burnNft",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "nftTokenMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "burnReceipt",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "burnConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenMetadata",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "userNftTokenAddress",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "nftProgramId",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "time",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    }
  ],
  "accounts": [
    {
      "name": "burnConfig",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "verifiedCreator",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "burnReceipt",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "mint",
            "type": "publicKey"
          },
          {
            "name": "time",
            "type": "i64"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "NoValidSigner",
      "msg": "No valid signer present"
    },
    {
      "code": 6001,
      "name": "InvalidNFTMetadata",
      "msg": "Invalid NFT Metadata"
    }
  ]
};

export const IDL: Burn = {
  "version": "0.1.0",
  "name": "burn",
  "instructions": [
    {
      "name": "initConfig",
      "accounts": [
        {
          "name": "burnConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "verifiedCreator",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "deleteConfig",
      "accounts": [
        {
          "name": "burnConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "burnNft",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "nftTokenMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "burnReceipt",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "burnConfig",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "nftTokenMetadata",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "userNftTokenAddress",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "nftProgramId",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "time",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    }
  ],
  "accounts": [
    {
      "name": "burnConfig",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "verifiedCreator",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "burnReceipt",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "mint",
            "type": "publicKey"
          },
          {
            "name": "time",
            "type": "i64"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "NoValidSigner",
      "msg": "No valid signer present"
    },
    {
      "code": 6001,
      "name": "InvalidNFTMetadata",
      "msg": "Invalid NFT Metadata"
    }
  ]
};
